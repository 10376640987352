import {html} from "htm/preact";
import {ExploitMethod} from "./app";
import {ExploitAvailability} from "../../src/library";

export function ExploitCard(props: { exploit: ExploitMethod, avail: ExploitAvailability, firmware?: string }) {
    const {exploit, avail, firmware} = props;
    const patched = (avail.patched && (!firmware || firmware >= avail.patched.version)) || false;
    const mayPatched = !patched && (!avail.latest || firmware && firmware > avail.latest.version) || false;
    const bgClass = patched ? 'bg-secondary-subtle opacity-75' : mayPatched ? 'bg-warning-subtle' : 'bg-primary-subtle';
    const iconClass = patched ? 'bi-exclamation-octagon-fill' : mayPatched ?
        'bi-question-octagon-fill' : 'bi-hand-thumbs-up-fill';
    return html`
      <div class=${`card p-3 mt-3 ${bgClass}`}>
        <h3>
          <i class="bi ${iconClass} me-2"/>
          <a class="stretched-link text-decoration-none text-light notranslate" href=${exploit.url}
             target="_blank" translate="no">${exploit.name}</a>
          ${patched ? html` (Patched)` : mayPatched && html` (Possibly patched)`}
        </h3>
        ${avail.latest && html`
          <div>
            <i class="bi bi-info-circle-fill me-2"/>Latest known rootable version: <b>${avail.latest?.version}
          </b>${mayPatched && firmware && html` (you have <b>${firmware}</b>)`}
          </div>
        `}
        ${avail.patched && html`
          <div><i class="bi bi-bandaid-fill me-2"/>Patched in version: <b>${avail.patched?.version}</b>
            ${firmware && html` (you have <b>${firmware}</b>)`}
          </div>
        `}
        ${exploit.expert && html`
          <div>
            <i class="bi bi-exclamation-triangle-fill me-2"/>Requires expert knowledge.
          </div>
        `}
      </div>
    `;
}